import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { tabPanel } from '../_interfaces/icontrols-common/tabview.interface';

@Component({
  selector: 'dx-tabview',
  templateUrl: './tabview.component.html',
  styleUrls: ['./tabview.component.scss']
})
export class TabViewComponent {
    @Input() tabPanels!: tabPanel[];
    @Input() activeIndex: number = 0;
    @Output() onTabClose = new EventEmitter();
    @Output() tabChange = new EventEmitter();
    ngOnInit() {

    }
    onTabClick(e:any){
        this.onTabClose.emit(e);
    }
    onTabChange(e:any){
      this.tabChange.emit(e);
    }
}

<div class="pwchange-full-body">
  <!-- <div class="px-logo-semitransparent">
    <img id="logoImage" [width]="160" [height]="30" src='assets/images/UpperLeft_Hori_White_129x26.svg'/>
  </div> -->

  <div class="pwchange-card shadow-lg rounded">
  
    <div class="pwchange-card-title row">
      <div class="col-12 color-pxtext-blue fs-1-10">
        Password saved successfully!
      </div>
    </div>
    <div class="pwchange-card-description row">
      <div class="col-12">
        <p>Congratulations you're ready to use <b><span class="color-gray">Contenthub</span></b>! You will be redirected automatically to the login page after 5 seconds.</p>
      </div>
    </div>
    <div class="pwchange-card-buttons">
      <button mat-flat-button color="primary" class="btn-full" (click)="OnBackBtnClick()">Go to Login Page ({{ countDown }})</button>
    </div>

    <!-- <div class="pwchange-card-disabled shadow-lg rounded" disabled>
        <div class="pwchange-card-buttons">
          <button mat-flat-button color="primary" class="btn-full" style="height: 20px; top: -5px;">&nbsp;</button>
        </div>
    </div> -->
    
  </div>

  

</div>
import { Component, Input} from '@angular/core';
import { IdxAdjacentItems } from '../_interfaces/icontrols-common/imenubar.interface';

@Component({
  selector: 'dx-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolBarComponent {
    @Input() controls!: IdxAdjacentItems[];
    ngOnInit() {

    }

}

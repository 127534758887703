<div class="unauthorized-container-color">
  <div class="unauthorized-container-image">
    <table style="width: 100%; height: 100%;">
      <tbody>
        <tr>
          <td class="align-middle">
            <div class="row">
              <div class="mx-auto" style="text-align: center;">
                <!-- <img class="unauthorized-logo" src="/app/_themes/official/kits/images/Logo-PX-white (2).png" /> -->
                <br />
                <br />
                <h2 class="display-10">You do not have the permission to access this page.</h2>
                <h6>Please contact your website administrator.</h6>
                <br />

                <p *ngIf="message.length == 0">
                  <span class="padded"><a class="btn btn-outline-info" role="button" (click)="navigateToAuthSso()">Click here for PureB2B Users</a></span>
                  <span><a class="btn btn-outline-info" role="button" (click)="navigateToPartnerLogin()">Click here for Partners</a></span>
                </p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

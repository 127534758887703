import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Toolbar } from 'primeng/toolbar';
import { AvatarComponent } from './Accessories/Avatar/avatar.component';
import { BadgeComponent } from './Accessories/Badge/badge.component';
import { ButtonComponent } from './Buttons/buttons.component';
import { BaseFieldComponent } from './Forms/FormFields/BaseField/basefield.component';
import { LinkFieldComponent } from './Forms/FormFields/LinkField/linkfield.component';
import { TextFieldComponent } from './Forms/FormFields/TextField/textfield.component';
import { GridComponent } from './Grid/grid.component';
import { MenuBarComponent } from './Menu/MenuBar/menubar.component';
import { PanelComponent } from './Panel/panel.component';
import { TabViewComponent } from './TabView/tabview.component';
import { ToolBarComponent } from './ToolBar/toolbar.component';
import { DxControlUtilsService } from './_common/dx-control-utils.service';
import { MaterialAngularModule } from './_library/material/material.angular.module';
import { PrimeNgAngularModule } from './_library/primeng/primeng.angular';

@NgModule({
  declarations: [
    MenuBarComponent,
    ButtonComponent,
    GridComponent,
    PanelComponent,
    AvatarComponent,
    ToolBarComponent,
    BaseFieldComponent,
    TextFieldComponent,
    LinkFieldComponent,
    TabViewComponent,
    BadgeComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeNgAngularModule,
    MaterialAngularModule
  ],
  exports: [
    MenuBarComponent,
    ButtonComponent,
    GridComponent,
    PanelComponent,
    AvatarComponent,
    ToolBarComponent,
    TextFieldComponent,
    LinkFieldComponent,
    TabViewComponent,
    BadgeComponent
  ],
  providers: [DxControlUtilsService],
  bootstrap: [],
  entryComponents: [
    MenuBarComponent,
    ButtonComponent,
    GridComponent,
    BaseFieldComponent,
    PanelComponent,
    TextFieldComponent,
    BadgeComponent,
    ToolBarComponent
  ]
})
export class DXComponentModules { }

import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Apollo, ApolloModule } from 'apollo-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AuthSsoInterceptor } from './_helpers/auth-sso.interceptor';
import { AuthLoginGuard } from './_helpers/login-guard.interceptor';
import { PortalGraphQlApiService } from './_services/dx-graphql-api.service';
import { PortalAuthenticationService } from './_services/dx-portal-auth.service';
import { DXComponentModules } from './_shared/dx-components/dx.component.module';
import { PortalMainService } from './_services/dx-portal-main.service';
import { PrimeNgAngularModule } from './_shared/dx-components/_library/primeng/primeng.angular';
import { GraphQlLeadsDownloadComponent } from './dx-apps/leads-main-page/graphql-leads-downloads';
import { PortalAuthGuard } from './_guards/authentication.guard';
import { UnauthorizedComponent } from './security/unauthorized.component';
import { ToastrModule } from 'ngx-toastr';
import { DxToastService } from './_services/dx-notifications.service';
import { LoginForgotComponent } from './login/forgot-password/login.forgot.component';
import { LoginForgotConfirmComponent } from './login/forgot-password/login.forgot.confirm.component';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { ChangePasswordSuccessfulComponent } from './login/change-password/change-password.successful.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SSOLoginComponent } from './login/content-sso-login/contenthub-sso-login';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    UnauthorizedComponent,
    GraphQlLeadsDownloadComponent,
    LoginForgotComponent,
    LoginForgotConfirmComponent,
    ChangePasswordComponent,
    ChangePasswordSuccessfulComponent,
    SSOLoginComponent
  ],
  imports: [
    BrowserModule,
    PrimeNgAngularModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    DXComponentModules,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 5000, // 15 seconds
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-top-center',
    }),
    NgbModule
  ],
  exports: [
    ApolloModule,
    HttpClientModule
  ],
  providers: [
    PortalAuthenticationService,
    PortalGraphQlApiService,
    PortalMainService,
    PortalAuthGuard,
    DxToastService,
    Apollo,
    { provide: HTTP_INTERCEPTORS, useClass: AuthSsoInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (portal: PortalMainService) => function() {return portal.initApplication();},
      deps: [PortalMainService],
      multi: true
    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, tap, map } from 'rxjs/operators'
import { GlobalsService } from '../_core/globals.service';

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  constructor(private http: HttpClient,
              public globals: GlobalsService) { }

  // login(formData: any) {
  //   let postHeaders: HttpHeaders = new HttpHeaders();
  //   postHeaders = postHeaders
  //     .set('Content-Type','application/x-www-form-urlencoded')
  //     .set('Authorization', "Basic " + btoa(`${formData.username}:${formData.password}`))

  //   let loginData = new HttpParams();
  //   loginData = loginData.set("username",formData.username)
  //     .set("password",formData.password)
  //     .set("login_type","pxpartner")
  //     .set("grant_type","password")
    
  //   return this.http.post<any>(
  //     `${this.globals.env.apiEndPoint.replace("/api","")}/oauth/token`,loginData, {headers: postHeaders})
  //     .pipe(
  //       tap(),
  //       catchError(this.handleError)
  //     );
  // }

  // resetPassword(email: string) {
  //   let userModel = { id: 0, partnerId: 0, password: '', firstName: '', lastName: '', email: email, partnerTypes: [0], isActive: false, isLocked: false, isDeleted: false, isPasswordResetRequired: false, ignorePassword: false };
  //   return this.http.post<any>(`${ this.globals.env.authUrl }/membership/resetpassword`, userModel)
  // }

  // changePartnerPassword(formData: any) {
  //   let changePasswordData = { email:formData.username, oldPassword: formData.oldpassword, newPassword: formData.newpassword };

  //   return this.http.post<any>(
  //     `${this.globals.env.authUrl}/membership/changepassword`,changePasswordData)
  //     .pipe(
  //       tap(),
  //       catchError(this.handleError)
  //     );
  // }

  logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('pxToken');
  }

  private handleError(err: HttpErrorResponse) {
    if (err.status === 400 ) {
      return throwError(err.error.error);
    }
    let errorMsg = (err.error.message != '' && err.error.message != undefined && err.error.message != null) ? err.error.message : 'An unexpected error occured';
    console.error(errorMsg);
    return throwError(errorMsg);
  }

}

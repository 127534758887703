import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'change-password-successful',
  templateUrl: './change-password.successful.component.html',
  styleUrls: [ './change-password.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ChangePasswordSuccessfulComponent implements OnInit {
  countDown!: number;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.countDown = 5;
    this.StartCountDown();
  }

  StartCountDown(): void {
    window.setInterval(() => {
      this.countDown -= 1;
      if (this.countDown === 0) {
        this.router.navigate(['login']);
      }
    }, 1000);
  }

  OnBackBtnClick(): void {
    this.router.navigate(['login']);
  }
}
import { Component, Input} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DxControlUtilsService } from '../../../_common/dx-control-utils.service';
import { BaseFieldComponent } from '../BaseField/basefield.component';

@Component({
  selector: 'dx-textfield',
  templateUrl: './textfield.component.html',
  styleUrls: ['./textfield.component.scss']
})
export class TextFieldComponent extends BaseFieldComponent {
    
    constructor(private textControlSvc: DxControlUtilsService){
       super(textControlSvc)
    }
    InitTextField() {
       this.ngOnInit();
    }

}

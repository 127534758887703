import { HttpHeaders } from '@angular/common/http';

export const UserTypes = {
  internalUser: 0,
  partnerUser: 1
};


export const HttpServiceHeader = {
  jsonHeaders: {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  },
  urlEncodedHeaders: {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { IApplicationMenu } from '../_interface/portal/portal-main-interface';
import { PortalAuthenticationService } from './dx-portal-auth.service';
import { GlobalsService } from '../_core/globals.service';
import { Router } from '@angular/router';

@Injectable()
export class PortalMainService {

  constructor(private route: Router, private authenticationService: PortalAuthenticationService, private globalSvc: GlobalsService) { }
  initApplication(): Promise<any> {
    return new Promise<any>(((resolve:any) => {
        // this.authenticationService.pxLogin().subscribe((data:any) => {
        //    resolve(data);
        // });
        resolve(true);
    }).bind(this));
  }
}

const MainAppMenuSample: IApplicationMenu[] = [
{ id: 1, code: "Toolset", text: 'Toolset', icon: 'pie-chart', accessClaim: "toolset", route: 'Toolset/default', ispartneruser: false },
{ id: 2, code: "UserManagement", text: 'User Management', icon: 'manage_accounts', accessClaim: "toolset", route: 'UserManagement', ispartneruser: false }]
import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { PortalAuthenticationService } from '../../_services/dx-portal-auth.service';
import { TextFieldComponent } from '../../_shared/dx-components/Forms/FormFields/TextField/textfield.component';
import { DxControlUtilsService } from '../../_shared/dx-components/_common/dx-control-utils.service';
import { DxToastService } from '../../_services/dx-notifications.service';

@Component({
  selector: 'dx-sso-login',
  templateUrl: './contenthub-sso-login.html',
  styleUrls: ['./contenthub-sso-login.scss']
})
export class SSOLoginComponent {

  title = 'DemandXchange';
  showInputViaEmail: boolean = false;
  loginForm!: FormBuilder;
  ssoCode!: string;
  @ViewChild("emailLogin") emailField!: TextFieldComponent;
  constructor(public authSvc: PortalAuthenticationService,
      private actiRoute: ActivatedRoute,
      private route: Router,
      private controlSvc: DxControlUtilsService,
      private dxToast: DxToastService)
  {

  }
  ngOnInit() {
    this.authSvc.showLoader = true;
    this.ssoCode = this.actiRoute.snapshot.queryParams['code'] || '/';
    this.authSvc.signInSSO(this.ssoCode).subscribe((isSuccess: Number) => {
      this.authSvc.showLoader = false;
      if (isSuccess == 0) {
        this.route.navigate(['/']);
      }else{
        this.dxToast.openErrorMessage('SSO Login encountered an error please call your IT Admin. Thank you');
      }
    });
  }
  navToApplication(){
     this.route.navigate(["forgot-password"]);
  }
}

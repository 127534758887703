import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from '@angular/router';
import { LoginService } from '../login.service';
import { PortalAuthenticationService } from "../../_services/dx-portal-auth.service";
import { DxToastService } from "src/app/_services/dx-notifications.service";

@Component({
  selector: 'px-login-forgot',
  templateUrl: './login.forgot.component.html',
  styleUrls: [ './login.forgot.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class LoginForgotComponent implements OnInit {
  private progressBarSessionId!: number;

  disabledReset!: boolean;
  email!: string;

  constructor(private router: Router,
              private loginSvc: LoginService,
              private portlAuthSvc: PortalAuthenticationService,
              private notificationSvc: DxToastService) { }

  ngOnInit(): void {
    this.email = '';
    this.disabledReset = false;
    this.portlAuthSvc.showLoader = false;
    //this.notificationSvc.setPageBusy(false);
  }

  OnResetBtnClick(): void {
    let emailAdd = this.email.trim();

    if (emailAdd === '') {
      this.notificationSvc.openErrorMessage('Kindly input the correct email address.');
      return;
    }

    this.disabledReset = true;
    this.portlAuthSvc.showLoader = true;
    //this.progressBarSessionId = this.notificationSvc.setPageBusy(true);
    this.portlAuthSvc.resetPassword(emailAdd).subscribe(
      (() => {
        this.portlAuthSvc.showLoader = false;
        //this.notificationSvc.safelyDisablePageBusy(this.progressBarSessionId);
        this.router.navigate([`forgotpass-confirm`]);
      }).bind(this),
      (() => {
        //this.notificationSvc.safelyDisablePageBusy(this.progressBarSessionId);
        this.disabledReset = false;
      }).bind(this));
  }

  OnBackBtnClick(): void {
    this.router.navigate(['login']);
  }
}
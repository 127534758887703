import { Component, Input} from '@angular/core';

@Component({
  selector: 'dx-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {
    @Input() value: string = "";
    @Input() size: "large" | "xlarge" | "" = "";
    @Input() severity: "warning" | "success" | "danger" | "info" | "dot" = "dot";
    ngOnInit() {
       
    }

}

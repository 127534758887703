import { Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'dx-linkfield',
  templateUrl: './linkfield.component.html',
  styleUrls: ['./linkfield.component.scss']
})
export class LinkFieldComponent {
    @Input() label: string = "";
    @Input() id: string = "";
    @Output() onClick = new EventEmitter();
    ngOnInit() {

    }
    handleClick(event: any){
      this.onClick.emit(event);
   }
}

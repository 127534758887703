import { Component} from '@angular/core';

@Component({
  selector: 'dx-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {

    ngOnInit() {

    }

}

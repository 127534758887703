<p-tabView id="closableTabView" (onChange)="onTabChange($event)" [activeIndex]="activeIndex" (onClose)="onTabClick($event)">
    <ng-container *ngFor="let tab of tabPanels" >
        <p-tabPanel [header]="tab.header" [selected]="tab.selected" [closable]="tab.closable">
            <ng-template pTemplate="content">
                <ng-container *ngTemplateOutlet="tab.content"></ng-container>
            </ng-template>
        </p-tabPanel>
    </ng-container>
</p-tabView>
<!-- 
    <p-tabPanel header="Header I" [selected]="true">
    <p>
         <dx-panel></dx-panel>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
    </p>
    </p-tabPanel>
    <p-tabPanel header="Header II" [closable]="true">
    <p>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
        illo inventore veritatis et quasi architecto beatae vitae dicta sunt
        explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
        odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
        voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non
        numquam eius modi.
    </p>
    </p-tabPanel>
    <p-tabPanel header="Header III" [closable]="true">
    <p>
        At vero eos et accusamus et iusto odio dignissimos ducimus qui
        blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
        et quas molestias excepturi sint occaecati cupiditate non provident,
        similique sunt in culpa qui officia deserunt mollitia animi, id est
        laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita
        distinctio. Nam libero tempore, cum soluta nobis est eligendi optio
        cumque nihil impedit quo minus.
    </p>
    </p-tabPanel> -->


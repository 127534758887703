<div class="full-body"><!-- redeploy 2019-11-27 -->
    <div id="changePasswordContainer">
        
        <form id="changePasswordForm" class="change-password-form p-4 shadow-lg border border-light rounded" [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
            
            <div class="change-password-title"><span class="color-blue fs-1-10">Create new password</span></div><br>

            <div class="form-group has-icon">
                <!-- <label for="username" class="control-label">Email</label> -->
                <img class="form-control-img icon-login-control" src="/assets/icons/account_circle.svg" />
                <input type="email" formControlName="username" readonly class="form-control" [ngClass]="{ 'is-invalid': f['username']['errors'] }" placeholder="Enter email" />

                <div *ngIf="f['username']['errors']" class="invalid-feedback">
                    <div *ngIf="f['username']['errors']['required']">Username is required</div>
                </div>

            </div>
            <div class="form-group has-icon">
                    <!-- <label for="oldpassword" class="control-label">Old Password</label> -->
                    <img class="form-control-img icon-login-control" src="/assets/icons/lock.svg" />
                    
                    <input type="password" password-eye formControlName="oldpassword" class="form-control bgc-light-gray" [ngClass]="{ 'is-invalid': f['oldpassword']['errors'] && f['oldpassword']['dirty'] }" placeholder="Enter old or temporary password"  minlength="6"/>

                    <div *ngIf="f['oldpassword']['errors']" class="invalid-feedback">
                        <div *ngIf="f['oldpassword']['errors']['required'] && f['oldpassword']['dirty']">Old or temporary password is required</div>
                        <!-- <div *ngIf="(f['oldpassword']['errors']['minlength'] || f['oldpassword']['errors']['maxlength']) && f['oldpassword']['dirty']">Password length should be 8-16 characters</div> -->
                    </div>
    
                </div>
            <div class="form-group has-icon">
                
                <!-- pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})" -->
                <img class="form-control-img icon-login-control" src="/assets/icons/lock.svg" />
                <input type="password" password-eye formControlName="newpassword" class="form-control bgc-light-gray" 
                    [ngClass]="{ 'is-invalid': f['newpassword']['errors'] && f['newpassword']['dirty'] }" 
                    placeholder="Enter new password" 
                    minlength="6" 
                    />
                <div *ngIf="f['newpassword']['errors']" class="invalid-feedback">
                    <div *ngIf="f['newpassword']['errors']['required'] && f['newpassword']['dirty']">Password is required</div>
                    <div *ngIf="(f['newpassword']['errors']['minlength'] || f['newpassword']['errors']['maxlength']) && f['newpassword']['dirty']">Password length should be minimum of 6 characters</div>
                     <!--<div *ngIf="f['newpassword']['errors']['pattern'] && f['newpassword']['dirty']">Password does not meet complexity requirement. Please make sure that there are at least 8 characters, at least 1 capital latter, at least 1 numeric character, and at least 1 special character</div> -->
                </div>
            </div>
            <div class="form-group has-icon">
                    
                    <img class="form-control-img icon-login-control" src="/assets/icons/lock.svg" />
                    <input type="password" password-eye formControlName="confirmpassword" class="form-control bgc-light-gray" [ngClass]="{ 'is-invalid': (f['confirmpassword']['errors'] || f['newpassword']['value'] != f['confirmpassword']['value']) && f['confirmpassword']['dirty'] }" placeholder="Confirm new password" minlength="6"/>
                    <div *ngIf="f['confirmpassword']['errors']" class="invalid-feedback">
                        <div *ngIf="f['confirmpassword']['errors']['required'] && f['confirmpassword']['dirty']">Password confirmation is required</div>
                        <div *ngIf="(f['confirmpassword']['errors']['minlength'] || f['confirmpassword']['errors']['maxlength']) && f['confirmpassword']['dirty']">Password length should be minimum of 6 characters</div>
                    </div>
                    <div *ngIf="f['newpassword']['value'] != f['confirmpassword']['value']" class="invalid-feedback">New passwords do not match</div>                    
                </div>
            <div class="form-group text-center">
                <button mat-flat-button color="primary" 
                    class="btn btn-primary btn-block text-uppercase" 
                    [disabled]="f['newpassword']['value'] != f['confirmpassword']['value'] || f['oldpassword']['errors'] || f['newpassword']['errors'] || f['confirmpassword']['errors'] || submitted">Change Password
                </button>
            </div>
        </form>
      
    </div>
</div>
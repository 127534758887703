import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { HttpLink } from 'apollo-angular/http';
import { GlobalsService } from '../../../_core/globals.service';
import { ApolloClient, NormalizedCacheObject ,ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { HttpHeaders } from '@angular/common/http';
import { PortalGraphQlApiService } from 'src/app/_services/dx-graphql-api.service';

@Injectable({
  providedIn: 'root',
})
export class CampaignGraphQlService extends PortalGraphQlApiService {

  constructor(private userHttpLink: HttpLink, private userGlobalSvc: GlobalsService) {
    super(userHttpLink, userGlobalSvc)
    this.initApolloConfig();
}

  getPartnerPacing(){
    return gql`query getPartnerPacing($input: GetPartnerPacingRequestModel){
      getPartnerPacing(input: $input)
        {
          data {
            pk
            sk
            type
            tenantId
            id
            channelType
            partnerId
            partnerName
            pacingStatus
            allocation
            startDate
            endDate
            numberOfDays
            currentDate
            daysElapsed
            expectedLeadsQualified
            currentLeadsQualified
            deficit
            underReview
            overdelivered
            gsi1pk
            version
            currentVersion
            createdOn
            updatedOn
          }
      }
    }`
  }

  sendNotificationToEmail(){
    return gql`mutation ($input: PacingStatusNotificationRequestModel)
    {
      sendPacingStatusNotification(input: $input) {
         data {
            success
        }
      }
    }`
  }


  getChannelPacing(){
    return gql`query getChannelPacing($input: GetChannelPacingRequestModel){
          getChannelPacing(input: $input) {
              pk
              sk
              type        
              id
              channelType
              allocation
              startDate
              endDate
              numberOfDays
              daysElapsed
              expectedLeadsQualified
              currentLeadsQualified
              isOffPace
              deficit
              gsi1pk
              version
              currentVersion
              createdOn
              updatedOn
        }
    }`
  }

  getCampaignPacing(){
    return gql`query getCampaignPacing($input: GetCampaignPacingRequestModel){
      getCampaignPacing(input: $input)
       {
        data {
          pk
          sk
          type
          tenantId
          id
          campaignId
          campaignName
          pacingSchedule
          pacingStatus
          progress
          leadsOrdered
          allocation
          underReview
          overdelivered
          deficit
          leadsToDate
          isOverrideSubmission
          runningDeficit
          periodDeficit
          gsi1pk
          version
          currentVersion
          createdOn
          updatedOn       
        }
       }
    }`
  }

  getGqlQueryOverpaceByCampaignId(){
    return gql`query getOverpaceByCampaignId($input: GetOverpaceRequestModel!) {
        getOverpaceByCampaignId(input: $input){
          lastPaginationToken
          data {
            items {
              pk
              sk
              type
              tenantId
              id
              campaignId
              partnerId
              email
              leadInfoJson
              ageInDays
              gsi1pk
              status
              leadId
              version
              currentVersion
              createdOn
              updatedOn
            }
            count
            totalCount
          }
        }
      }`
  }

  getGqlMutationExportMatersCampaigns(){
    return gql`mutation ($inputModel: ExportMaterializedCampaignsRequestModel) {
      exportMaterializedCampaigns(input: $inputModel){
        data{
          success
        }
      }
    }`
  }

  getGqlMutationAjdustPartnerAllications(){
    return gql`mutation ($inputModel: AllocationUpdateRequestModel) {
      adjustPartnerAllocations(input: $inputModel){
        data{
          success
        }
      }
    }`
  }

  getGqlMutationSetAsSubmitted(){
    return gql`mutation setOverpaceStatus($input: SetOverpaceStatusRequestModel) {
        setOverpaceStatus  (input: $input)
        {
          data {
            items {
              id
              pk
              sk        
              partnerId
              campaignId
              createdOn
              updatedOn
              status
              leadId
              leadInfoJson
            }
          }
        } 
    }`
  }

  getCampaignProgressData(){
      return gql`query  ($channelTypeModel3: GetChannelPacingRequestModel
        $channelTypeModel2: GetChannelPacingRequestModel,  
        $partnersModel: GetPartnersPacingRequestModel,
        $campaignIdModel: GetCampaignPacingRequestModel, 
        $channelTypeModel: GetChannelPacingRequestModel){
        getCampaignPacing(input: $campaignIdModel) {
          data {
                  pk
                  sk
                  type
                  tenantId
                  id
                  campaignId
                  campaignName
                  pacingSchedule
                  pacingStatus
                  progress
                  leadsOrdered
                  allocation
                  underReview
                  overdelivered
                  deficit
                  leadsToDate
                  isOverrideSubmission
                  runningDeficit
                  periodDeficit
                  gsi1pk
                  version
                  currentVersion
                  createdOn
                  updatedOn
                }
        }
        getChannel2: getChannelPacing(input:  $channelTypeModel2) {
          data {  
            pk
            sk
            type        
            id
            channelType
            pacingStatus
            progress
            allocation
            startDate
            endDate
            numberOfDays
            currentDate
            daysElapsed
            expectedLeadsQualified
            currentLeadsQualified
            underReview
            overdelivered
            deficit
            runningDeficit
            periodDeficit
            gsi1pk
            version
            currentVersion
            createdOn
            updatedOn
          }
        }
        getChannel1: getChannelPacing(input:  $channelTypeModel) {
           data {    	
                pk
                sk
                type        
                id
                channelType
                pacingStatus
                progress
                allocation
                startDate
                endDate
                numberOfDays
                currentDate
                daysElapsed
                expectedLeadsQualified
                currentLeadsQualified
                underReview
                overdelivered
                deficit
                runningDeficit
                periodDeficit
                gsi1pk
                version
                currentVersion
                createdOn
                updatedOn
          }
        }
        getChannel3: getChannelPacing(input:  $channelTypeModel3) {
            data {
                pk
                sk
                type        
                id
                channelType
                pacingStatus
                progress
                allocation
                startDate
                endDate
                numberOfDays
                currentDate
                daysElapsed
                expectedLeadsQualified
                currentLeadsQualified
                underReview
                overdelivered
                deficit
                runningDeficit
                periodDeficit
                gsi1pk
                version
                currentVersion
                createdOn
                updatedOn
            }
        }
        getPartnersPacing(input: $partnersModel){
           lastPaginationToken
           data {
              items {
                pk
                sk
                type
                tenantId
                id
                channelType
                partnerId
                partnerName
                pacingStatus
                progress
                allocation
                startDate
                endDate
                monthlyAllocations
                numberOfDays
                currentDate
                daysElapsed
                expectedLeadsQualified
                currentLeadsQualified
                deficit
                underReview
                overdelivered
                isPOFSent
                runningDeficit
                periodDeficit
                gsi1pk
                isDeleted
                version
                currentVersion
                createdOn
                updatedOn
              }
              count
              totalCount
           }
        }
      }`
  }
  
  getListCampaign(){
    return gql`query ($inputModel: GetMaterializedCampaignsRequestModel){
          getMaterializedCampaigns(input:$inputModel){
            data{
              items {
                campaignId
                campaignName
                client
                progress
                startDate
                endDate
                programEndDate
                status
                statusCode
                type
                company
                campaignCpl
                cpl
                deliveryType
                deliveryFrequency
                pacing
                talxAbm
                projectManager
                cxManager
                agencyRep
                leadstoDate
                dataCount
                opportunityId
                leadsToday
                leadsThisMonth
                pxCampaignId
                pxSegmentId
                leadsInQa
                prio
                pacingStatus
                pacingStatusCode
                partnerId
                adminPartnerId
                allocation
                leadsDelivered
                totalOrder
                totalDelivered
                underReview
                qualified
                unqualified
                duplicates
                outstanding
                returned
              }
              skip
              take
              totalCount 
            }    
          }
      }`
  }

  getEmailTemplate(){
    return gql`query ($input: PacingStatusNotificationRequestModel){
      getPacingStatusNotificationTemplateContent(input:$input) {
         data {
          templateContent
          success
        }
      }
    }`
  }

  getOverpaceLeadFile(){
    return gql`mutation ($input: GenerateOverpaceLeadFileRequestModel) {
      generateOverpaceLeadFile(input: $input){
        data{
          fileString
        }
      }
    }`
  }

  getDXListOfLead(){
     return gql`query ($inputModel: GetMaterializedLeadsRequestModel){
      getDXMaterializedLeads (input:$inputModel){
        data{
          items {
            id
            leadsLegacyTableSource
            memberId
            leadsStatus
            campaignId
            createdAt
            updatedAt
            partnerId
            firstName
            lastName
            email
            linkedinUrl
            companyName
            address
            city
            state
            province
            country
            phone
            partner
            companySize
            industry
            jobTitle
            jobFunction
            jobArea
            jobLevel
            clientName
            campaignCompanyName
            campaignName
            campaignStartDate
            campaignTotalLeadOrder
            unQualifiedReason
            reasonForReturn
            dateReturned
          }
          skip
          take
          totalCount      
        }
      }
    }`;
  }

  getListOfLead(){
    return gql`query ($inputModel: GetMaterializedLeadsRequestModel){
     getMaterializedLeads (input:$inputModel){
       data{
         items {
           id
           leadsLegacyTableSource
           memberId
           leadsStatus
           campaignId
           createdAt
           updatedAt
           partnerId
           firstName
           lastName
           email
           linkedinUrl
           companyName
           address
           city
           state
           province
           country
           phone
           partner
           companySize
           industry
           jobTitle
           jobFunction
           jobArea
           unQualifiedReason
           reasonForReturn
           dateReturned
         }
         skip
         take
         totalCount      
       }
     }
   }`;
 }

 getDXExportLeadMutation(){
  return gql`mutation ($inputModel: ExportMaterializedLeadsRequestModel){
    exportDXMaterializedLeads (input:$inputModel){
      data{
        success   
      }
    }
  }`
}


  getExportLeadMutation(){
    return gql`mutation ($inputModel: ExportMaterializedLeadsRequestModel){
      exportMaterializedLeads (input:$inputModel){
        data{
          success   
        }
      }
    }`
  }

  getDownloadLeadsQuery(){
    return gql`query downloadExportedDXMaterializedLeads($inputModel: DownloadExportedMaterializedLeadsRequestModel){
      downloadExportedDXMaterializedLeads(input:$inputModel){
        data {
          presignedUrl
        }
      }
    }`
  }
  getDownloadCampainQuery(){
    return gql`query downloadExportedDXMaterializedLeads($inputModel: DownloadExportedMaterializedCampaignsRequestModel){
      downloadExportedDXMaterializedLeads(input:$inputModel){
        data {
          presignedUrl
        }
      }
    }`
  }

  getCampaignLeadStatusQuery(){
     return gql`query ($inputModel: GetCampaignLeadsStatsRequestModel){
      getCampaignLeadsStatus( input: $inputModel){
        data {
          campaignId
          partnerId
          totalDelivered
          underReview
          qualified
          unqualified
          duplicates
          returned
          underReviewInQA
          underReviewUQNYD
          leadsInQA
          leadsInResourceViews
          leadsInSurveyResponse
          tmLeadCount      
          emailLeadCount
          partnerLeadCount
          channel
          unprocessedCount
          unProcessedNonBadDataCount
        }
      }
    }`
  }
}


import { Component } from '@angular/core';
import { Route, Router } from '@angular/router';
// import { IdxMenuItem } from './../_shared/dx-components/_interfaces/iprimeng/irefobject.interface';

@Component({
  selector: 'dx-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  //appMenuItems!: IdxMenuItem[];
  title = 'DXPortal.Angular';
  constructor(private router: Router){

  }
  ngOnInit() {

  }
  routeToApp(app: string){
    this.router.navigate([app])
  }
}

<div class="confirm-full-body">
  <div class="confirm-card">
    <!-- <div class="confirm-card-image row">
      <div class="col-12">
        <object data="/app/_themes/official/kits/icons/email-check.svg"></object>
      </div>
    </div> -->
    <div class="confirm-card-title row">
      <div class="col-12">
        Reset password link sent!
      </div>
    </div>
    <div class="confirm-card-description row">
      <div class="col-12">
        <p>We have sent a reset password link to {{email}}. Please check. You will be redirected automatically to the login page after 5 seconds.</p>
      </div>
    </div>
    <div class="confirm-card-buttons">
      <button mat-flat-button color="primary" class="btn-full" (click)="OnBackBtnClick()">Back to Login Page ({{ countDown }})</button>
    </div>
  </div>
</div>

<ng-template #ctrlTmp let-ctrllist="ctrllist" let-location="location">
    <ng-container  *ngFor="let ctrl of ctrllist">
        <ng-container *ngIf="ctrl.config.location === location" >
            <ng-container *ngIf="ctrl.controlType === 'avatar'" >
                <dx-avatar></dx-avatar>
            </ng-container>
            <ng-container *ngIf="ctrl.controlType === 'search'" >
                <input type="text" pInputText placeholder="Search">
            </ng-container>
            <ng-container *ngIf="ctrl.controlType === 'button'" >
                <dx-button [text]="ctrl.config.text" [icon]="ctrl.config.icon" (onClick)="ctrl.config.onClick($event)"></dx-button>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<p-toolbar>
    <div class="p-toolbar-group-start">
        <ng-container
          *ngTemplateOutlet="ctrlTmp;context:{ctrllist : controls, location: 'left'}">
        </ng-container>
    </div>
    <div class="p-toolbar-group-center">
        <ng-container
          *ngTemplateOutlet="ctrlTmp;context:{ctrllist : controls, location: 'center'}">
        </ng-container>
    </div>
    <div class="p-toolbar-group-end">
        <ng-container
          *ngTemplateOutlet="ctrlTmp;context:{ctrllist : controls, location: 'right'}">
        </ng-container>
    </div>
</p-toolbar>
import { Injectable } from '@angular/core';
import { GlobalsService } from '../../../_core/globals.service';
import { Observable } from 'rxjs';
import { CampaignGraphQlService } from './campaign-graphql.service';

@Injectable()
export class LeadsService {

  constructor(private globalSvc: GlobalsService, private gqlService: CampaignGraphQlService) { 

  }

  getDXCampaignLeadsData(params: {}): Observable<any>{
    return new Observable<any>((observer) => {
      this.gqlService.apolloClient.watchQuery({
        query: this.gqlService.getDXListOfLead(),
        variables: {
          inputModel: params
        }
      }).subscribe((res:any) => {
        //let dataFromRes = res.data? res.data["getDXMaterializedLeads"]["data"] : res.data;
        //let respData = this.prepareLeadListColumn(dataFromRes?dataFromRes["items"] : []);
        //let objGridaDataSource = { data: respData, totalCount:  dataFromRes ? dataFromRes["totalCount"] : 0} as IGridDataSource
        observer.next(this.gqlService.getDataResponse("getDXMaterializedLeads", res));
      });
    });
  }

  exportDXMaterializedLeads(params: any){
    return new Observable<any>((observer) => {
        let paramsObjInput: any = {
          columns: params['columns'],
          filterCriteria: params['filterCriteria'],
          pagination: params["pagination"],  
          exportType:  params["exportType"]        
        }

        this.gqlService.apolloClient.mutate({
          mutation: this.gqlService.getDXExportLeadMutation(),
          variables: {
            inputModel: paramsObjInput
           }
        }).then(res => {
          observer.next(res);
        });
    })
  }

  downloadMaterializedLeads(params: any){
    return new Observable<any>((observer) => {
        this.gqlService.apolloClient.watchQuery({
          query: this.gqlService.getDownloadLeadsQuery(),
          variables: {
            inputModel: {
              reference: params["reference"],
            }
           }
        }).subscribe(res => {     
          observer.next(res);
        });
    })
  }
}
import { Component, Input } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DxControlUtilsService } from "../../../_common/dx-control-utils.service";

@Component({
  selector: "dx-base-field",
  templateUrl: "./basefield.component.html",
  providers: [DxControlUtilsService]
})
export class BaseFieldComponent {
  @Input() label: string = "";
  @Input() id: string = "";
  @Input() formControler!: FormBuilder;
  @Input() value: any;
  @Input() registerForm!: FormGroup;
  @Input() type!: string;
  public dxUtilService!: DxControlUtilsService;
  constructor(private controlSvc: DxControlUtilsService) {
      this.dxUtilService = controlSvc;
  }

  ngOnInit() {
      
      if(!this.formControler){
          this.formControler = new FormBuilder();
      }
      this.dxUtilService.addComponent(this);
      this.registerForm = this.formControler.group({});
      this.registerForm.addControl(this.id, new FormControl());
  }

  getValue(){
      return this.registerForm.controls[this.id].value;
  }

  setValue(){
    this.registerForm.controls[this.id].patchValue(this.value);
  }

  getComponent(){
     this.dxUtilService.getComponent(this.id);
  }

}

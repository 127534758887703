<div class="full-body">
  <div class="login-form rounded shadow-lg">
    <div class="login-title row">
      <div class="col-12">
        Forgot Password?
      </div>
    </div>

    <div class="login-first-line row">
      <div class="col-12">
        <p>
          Don't worry we are here to help you recover your password. Enter the email address you used to access your account.
        </p>
      </div>
    </div>

    <div class="login-second-line row">
      <div class="col-12 form-group has-icon">
        <img class="form-control-img icon-login-control" src="/assets/icons/account_circle.svg" />
        <input type="text" placeholder="Email" class="form-control" [(ngModel)]="email">
      </div>
    </div>

    <div class="login-buttons">
        <div style="width: 55%;">
          <dx-linkfield id="forgot-back" label="Back" (onClick)="OnBackBtnClick()"></dx-linkfield>
        </div>
        <div>
          <dx-linkfield id="forgot-reset" label="Reset Password" (onClick)="OnResetBtnClick()"></dx-linkfield>
        </div>
      <!-- <button mat-flat-button disableRipple class="btn-left" (click)="OnBackBtnClick()"><i class="fas fa-arrow-left"></i>Back</button>
      <button mat-flat-button color="primary" class="btn-right" (click)="OnResetBtnClick()" [disabled]="disabledReset">Reset Password</button> -->
    </div>
  </div>
</div>

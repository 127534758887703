<p-panel [styleClass]="'dx-panel-base'">
    <ng-template *ngIf="toolbar">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-template>
    <ng-template *ngIf="header" pTemplate="header">
        <ng-container *ngTemplateOutlet="header"></ng-container>
    </ng-template>
    <ng-container *ngTemplateOutlet="content"></ng-container>
    <ng-template *ngIf="footer" pTemplate="footer">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </ng-template>
</p-panel>
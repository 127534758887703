import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'dx-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent {
    @Input() content!: TemplateRef<any>;
    @Input() header!: TemplateRef<any>;
    @Input() footer!: TemplateRef<any>;
    @Input() toolbar!: TemplateRef<any>;
    @Input() location: "center" | "left" | "right" | "top" | "bottom" = "center";
    ngOnInit() {

    }    
}

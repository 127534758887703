import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { GlobalsService } from '../../_core/globals.service';
import { LoginService } from '../login.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { PortalAuthenticationService } from '../../_services/dx-portal-auth.service';
import { DxToastService } from 'src/app/_services/dx-notifications.service';

// redeploy 2019-11-27
@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {
  changePasswordForm!: FormGroup
  submitted: boolean = false
  errorMessage!: string
  errors: string[] = []
  isForcedReset: boolean = false
  email: string = ''
  passwordPattern=/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])/
  
  constructor(
    public globals: GlobalsService, 
    public loginService: LoginService,
    private formBuilder: FormBuilder, 
    public portalAuthSvc: PortalAuthenticationService,
    private router: Router, 
    private route: ActivatedRoute,
    public notificationSvc: DxToastService,
  ){} 

  ngOnInit() {

    this.route.params.subscribe(p => { 
      if(p)
      {
        this.isForcedReset = true
        this.email = p['email']
      }
      this.portalAuthSvc.showLoader = false;
   });

    this.changePasswordForm = this.formBuilder.group({
        //username: new FormControl({value: this.email}, Validators.required),
        username: [this.email, Validators.required],
        oldpassword: ['', Validators.required],
        newpassword: ['', [Validators.required]],  //, Validators.pattern(this.passwordPattern)
        confirmpassword: ['', Validators.required]
    })   
    
  }
  
  get f() { return this.changePasswordForm.controls }
  
  changePassword(){
    this.portalAuthSvc.showLoader = true;
    this.submitted = true
    this.portalAuthSvc.changePassword(this.changePasswordForm.value).subscribe(      
      (p:any) => {
        this.notificationSvc.openSuccessfulMessage('Password saved successfully', 3500)
        this.portalAuthSvc.showLoader = false;
        this.router.navigate([`change-password-successful`]);        
        
        this.submitted = false
      },
      (error:any) => {        
        this.errorMessage = <any>error
        this.notificationSvc.openErrorMessage('Password NOT saved, please check your inputs.')
        this.portalAuthSvc.showLoader = false;
        this.submitted = false
      }
    )
  }

}

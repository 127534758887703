<ng-template #rowdefinition let-rowData="rowData" let-columns="columns">

</ng-template>
<p-table [columns]="columns" [value]="dataSource.data" [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-striped"
[paginator]="showPaginator"
[dataKey]="dataKey"
[rows]="dataSource.pageSize"
[(selection)]="selectedRowData"
[showCurrentPageReport]="false"
[alwaysShowPaginator]="showPaginator"
[rowsPerPageOptions]="dataSource.pageOption"
[lazy]="true" (onLazyLoad)="pageLoadEvent($event)"
[loading]="loading"
[(first)]="pageFirst"
[totalRecords]="dataSource.dataTotalCount"
[selectionMode]="rowSelectionType">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngIf="enableExpandableRow">
                <th [class]="'header-field-expander'"></th>
            </ng-container>
            <ng-container *ngFor="let col of columns">
                <th [attr.class]="'header-field-' + col.field">
                    <div *ngIf="col.type !== 'checkbox'">{{col.header}}</div>
                    <div *ngIf="col.type === 'checkbox'">
                         <div *ngIf="!col.hideHeaderBox" style="display: flex;flex-direction: column;text-align: center;">
                             {{col.header}}<p-tableHeaderCheckbox (click)="col.config.action(col)"></p-tableHeaderCheckbox></div>
                     </div>
                 </th>
            </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
        <tr [pSelectableRow]="rowData" [pSelectableRowDisabled]="!enableRowSelection && !rowData['isSelectable']">
            <ng-container *ngIf="enableExpandableRow">
                <td>
                    <button type="button" pButton pRipple [pRowToggler]="rowData" (click)="onClickExpandRow(expanded, rowData)" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
            </ng-container>
            <td *ngFor="let col of columns" [attr.class]="'td-field-' + col.field">
                <div *ngIf="col.type === 'string'">{{rowData[col.field]}}</div>
                <div *ngIf="col.type === 'link'">
                    <a *ngIf="rowData[col.config?.type] !== 'text'" target="_blank" class="underline text-blue-500" [routerLink]="col.config.action" [queryParams]="rowData[col.config.queryParams]"  >
                        <span [pTooltip]="rowData[col.config?.tooltipText]">{{ rowData[col.field] }}</span>
                        </a>
                        <div *ngIf="rowData[col.config?.type] === 'text'">
                        <span>{{ rowData[col.field].label }}</span>
                        </div>
                </div>
                <div *ngIf="col.type === 'linkLabel'">
                        <a *ngIf="rowData[col.field][col.config?.type] !== 'text'" target="_blank" class="underline text-blue-500" [href]="rowData[col.field].link" >
                        <span [pTooltip]="rowData[col.config?.tooltipText]">{{ rowData[col.field].label }}</span>
                        </a>
                        <div *ngIf="rowData[col.field][col.config?.type] === 'text'">
                        <span>{{ rowData[col.field].label }}</span>
                        </div>
                </div>
                <div *ngIf="col.type === 'number'">{{rowData[col.field]}}</div>
                <div *ngIf="col.type === 'enum'">{{ col.enum[rowData[col.field]]}}</div>
                <div *ngIf="col.type === 'date'">{{rowData[col.field] | date:'MM/dd/YYYY'}}</div>
                <div *ngIf="col.type === 'datetime'">
                    <div *ngIf="rowData[col.field]">{{ rowData[col.field] | date:'MM/dd/YYYY h:mm:ss'}}</div>
                </div>
                <div *ngIf="col.type === 'chip'"><p-chip [label]="rowData[col.field]" [styleClass]="rowData[col.config?.color]" [pTooltip]="rowData[col.config?.tooltipText]"></p-chip></div>
                <div *ngIf="col.type === 'checkbox'" style="display: flex;flex-direction: column;text-align: center;"><p-tableCheckbox *ngIf="rowData[col.config?.visible]" [disabled]="rowData[col.config?.disabled]" (click)="col.config.action(rowData)"  [value]="rowData"></p-tableCheckbox></div>
            </td>
        </tr>
    </ng-template>
    <ng-container *ngIf="enableExpandableRow">
        <ng-template pTemplate="rowexpansion" let-rowData>
            <tr>
                <td  class="expanded-content" [attr.colspan]="columns.length + 1">
                    <ng-container *ngTemplateOutlet="rowContent;context:{row:rowData}"></ng-container>
                </td>
            </tr>  
        </ng-template>
    </ng-container>
</p-table>
<!-- <p-chip label="Apple" icon="pi pi-apple"></p-chip> -->
<!-- currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" -->
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CampaignGraphQlService } from './_services/campaign-graphql.service';
import { LeadsService } from './_services/leads.service';

@Component({
  selector: 'graphql-leads-downloads',
  template: `<div class="container-fluid" style="padding: 15px;padding-left:40px;padding-right:40px;">
    <div>
        <h2 class="label-type-1 inline-header">Leads Downloads</h2>
    </div>
    <hr />
    <div class="col-12">
        {{message}}
    </div>
    </div>`,
  providers: [LeadsService]
})
export class GraphQlLeadsDownloadComponent implements OnInit {
  //private progressBarSessionId: number;

  message: string = '';

  constructor(
    //private notificationSvc: NotificationsService,
    private route: ActivatedRoute,
    private pxCampaignGraphQlSvc: CampaignGraphQlService,
    private campaignSvc: LeadsService,
  ) { 
    this.pxCampaignGraphQlSvc.initApolloConfig();
  }
  
  ngOnInit(): void {
   // this.progressBarSessionId = this.notificationSvc.setPageBusy(true);

    let fileReference = this.route.snapshot.queryParamMap.get('exportedLeadsReference');
    if (fileReference) {
      this.message = `Downloading...`;

      let payload = {
        reference: fileReference
      }
      this.campaignSvc.downloadMaterializedLeads(payload).subscribe(
        res=>{
          let downloadURL = res.data.downloadExportedDXMaterializedLeads.data.presignedUrl
          if(downloadURL){
            window.open(downloadURL, '_self')
            this.message = 'File has been downloaded';
          }else{
            this.message = `Failed to download file`;
          }
          //this.notificationSvc.setPageBusy(false);
        },
        err => {
          this.message = `Failed to download file`;
          //this.notificationSvc.setPageBusy(false);
        }
      );
    }
  }
}

import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { PortalAuthenticationService } from '../_services/dx-portal-auth.service';
import { TextFieldComponent } from '../_shared/dx-components/Forms/FormFields/TextField/textfield.component';
import { DxControlUtilsService } from '../_shared/dx-components/_common/dx-control-utils.service';
import { DxToastService } from '../_services/dx-notifications.service';

@Component({
  selector: 'dx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  title = 'DemandXchange';
  showInputViaEmail: boolean = false;
  loginForm!: FormBuilder;
  returnUrl!: string;
  @ViewChild("emailLogin") emailField!: TextFieldComponent;
  constructor(public authSvc: PortalAuthenticationService,
      private actiRoute: ActivatedRoute,
      private route: Router,
      private controlSvc: DxControlUtilsService,
      private dxToast: DxToastService)
  {

  }
  ngOnInit() {
    
    //this.returnUrl = this.actiRoute.snapshot.queryParams['returnUrl'] || '/';
  }
  navToForgotPassword(){
     this.route.navigate(["forgot-password"]);
  }


  showLoginViaEmail(){
     this.showInputViaEmail = !this.showInputViaEmail;
  }

  login(){
    //console.log(this.emailField.getValue());
    let emailField = this.controlSvc.getComponent("emailLogin");
    //let orgIdField = this.controlSvc.getComponent("orgId");
    let passwordField = this.controlSvc.getComponent("userpw");
    this.authSvc.showLoader = true;
    this.authSvc.login(emailField.getValue(), passwordField.getValue(), 1).subscribe((isSuccess: Number) => {
      this.authSvc.showLoader = false;
      if (isSuccess == 0) {
        this.route.navigate(['/']);
      }else{
        this.dxToast.openErrorMessage('Incorrect username and password');
        //this.route.navigate(['login']);
      }
    });
  }

}

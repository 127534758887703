import { Component, Input } from '@angular/core';
import { IdxAdjacentItems } from '../../_interfaces/icontrols-common/imenubar.interface';
import { IdxMenuItem } from '../../_interfaces/iprimeng/irefobject.interface';

@Component({
  selector: 'dx-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss']
})
export class MenuBarComponent {
    @Input() menuItems!: IdxMenuItem[];
    @Input() adjacentItems!: IdxAdjacentItems[];
    ngOnInit() {

    }    
}

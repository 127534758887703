import { Component, Host, Input, OnInit, Optional, Self, QueryList, TemplateRef} from '@angular/core';
import { Observable } from 'rxjs';
import { DxControlUtilsService } from './dx-control-utils.service';

@Component({
    selector: 'app-base-cmp',
    template: ``,
    providers: [DxControlUtilsService]
})
export class BaseComponent implements OnInit {
    @Input() id!: string;
    @Input() classNames: string = 'dsep-base';
    @Input() cls!: string;

    ownerCmp!: object;
    config: any = this;
    componentName: string = 'baseComponent';
    constructor(private _globalSvc: DxControlUtilsService){

    }

    ngOnInit(){
        this._globalSvc.registeredComponents[this.config.id] = this;
    }

    applyConfig(config: {}){
        this._globalSvc.applyObject(this, this.config, true);
    }

    getComponent(id: string){
       return this._globalSvc.registeredComponents[id];
    }

    addCls(cls: string){
        let arrClass =  this.classNames.split(' ');
        if(arrClass.indexOf(cls) < 0){
            arrClass.push(cls);
            this.classNames = arrClass.join(' ');
        }
    }

    removeCls(cls: string){
        let arrClass =  this.classNames.split(' ');
        if(arrClass.indexOf(cls) >= 0){
            arrClass = arrClass.splice(arrClass.indexOf(cls), 1);
            this.classNames = arrClass.join(' ');
        }
    }
}

import { RouterModule, Routes } from '@angular/router'
import { PortalAuthGuard } from './_guards/authentication.guard';
import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AuthLoginGuard } from './_helpers/login-guard.interceptor';
import { GraphQlLeadsDownloadComponent } from './dx-apps/leads-main-page/graphql-leads-downloads';
import { UnauthorizedComponent } from './security/unauthorized.component';
import { LoginForgotComponent } from './login/forgot-password/login.forgot.component';
import { LoginForgotConfirmComponent } from './login/forgot-password/login.forgot.confirm.component';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { ChangePasswordSuccessfulComponent } from './login/change-password/change-password.successful.component';
import { SSOLoginComponent } from './login/content-sso-login/contenthub-sso-login';
// import { LoginComponent } from './login/login.component';
// import { PortalRouteResolver } from './_core/portalRoute.resolver';
// import { UnauthorizedComponent } from './security/unauthorized.component';
// import { ChangePasswordComponent } from './login/change-password/change-password.component';
// import { ChangePasswordSuccessfulComponent } from './login/change-password/change-password.successful.component';
// import { LoginForgotComponent } from './login/forgot-password/login.forgot.component';
// import { LoginForgotConfirmComponent } from './login/forgot-password/login.forgot.confirm.component';

const AppRoutes: Routes = [
  { path: 'change-password', component: ChangePasswordComponent, pathMatch: 'full'},
  { path: 'change-password-successful', component: ChangePasswordSuccessfulComponent, pathMatch: 'full'},
  { path: 'change-password/:email', component: ChangePasswordComponent, pathMatch: 'full'},
  { path: 'forgot-password', component: LoginForgotComponent, pathMatch: 'full' },
  { path: 'forgotpass-confirm', component: LoginForgotConfirmComponent, pathMatch: 'full' },
  { path: 'unauthorized', component: UnauthorizedComponent, pathMatch: 'full' },
  { path: 'Home', component: HomeComponent, pathMatch: 'full' },
  { path: '', redirectTo: 'Home', pathMatch: 'full'},
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'content-hub-sso-login', component: SSOLoginComponent, pathMatch: 'full' },
  { path: 'leads-download', component: GraphQlLeadsDownloadComponent, pathMatch: 'full' },
  { path: 'UserManagement', loadChildren: () => import('./dx-apps/user-management/user-management.module').then(m => m.UserManagementModule)},
  { path: 'Organization', loadChildren: () => import('./dx-apps/organization/organization.module').then(m => m.OrganizationModule)},
  { path: 'LeadsMonitor', loadChildren: () => import('./dx-apps/leads-monitor/leadsmonitor.module').then(m => m.LeadsMonitorModule)},
  { path: 'CampaignSync', data:{ claimType: "44", title: "CampaignSync"} , canActivate: [PortalAuthGuard], loadChildren: () => import('./dx-apps/campaign-sync/campaign-sync.module').then(m => m.CampaignSyncModule)},
  { path: 'Leads', data:{ claimType: "23", title: "Leads" } , canActivate: [PortalAuthGuard], loadChildren: () => import('./dx-apps/leads-main-page/leads-main.module').then(m => m.LeadsDXModule)},
  { path: 'PowerBi',data:{ title: "PowerBi" } , loadChildren: () => import('./dx-apps/powerbi/powerbi.module').then(m => m.PowerbiModule)}
];

@NgModule({
  imports: [
      RouterModule.forRoot(AppRoutes,{ enableTracing: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }


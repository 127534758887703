import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'dx-button',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss']
})
export class ButtonComponent {
    @Input() type: "button" | "outline" = "button";
    @Input() text!: string;
    @Input() icon!: string;
    @Input() location: "left" | "right" | "center" | "top" | "bottom" = "left"
    @Output() onClick = new EventEmitter();
    ngOnInit() {

    }
    handleClick(event: any){
        this.onClick.emit(event);
    }
}

import { first } from "rxjs";
import { IOAuthToken } from "../../_interface/portal/oAuth.interface";
import { ILoggedOnUser } from "../../_interface/portal/loggedUser.interface";
import { Optional } from "@angular/core";


export class LoggedOnUserModel implements ILoggedOnUser {
    constructor(
      public productVersion?: string,
      public lastName?: string,
      public firstName?: string,
      public email?: string,
      public oauthToken?: IOAuthToken,
      public isActive?: boolean,
      public isDeleted?: boolean,
      public isLocked?: boolean,
      public isAdmin?: boolean,
      public userType?: number,
      public partnerId?: number,
      public partnerName?: string,
      public partnerAdminCode?: string,
      public id?: number,
      public userGroupIds?: number[],
      public isPasswordResetRequired?: boolean,
      public lastLoginOn?: Date,
      public isAuthenticated?: boolean,
      public claims?: string,
      public avatarImage?: string,
      public sessionCreated?: boolean
    ){
        this.productVersion = productVersion;
        this.lastName = lastName;
        this.firstName =  firstName;
        this.email = email;
        this.oauthToken = oauthToken;
        this.isActive = isActive;
        this.isDeleted = isDeleted;
        this.isLocked = isLocked;
        this.isAdmin = isAdmin;
        this.userType = userType;
        this.partnerId = partnerId;
        this.partnerName = partnerName;
        this.partnerAdminCode = partnerAdminCode;
        this.id = id;
        this.userGroupIds = userGroupIds;
        this.isPasswordResetRequired = isPasswordResetRequired;
        this.lastLoginOn = lastLoginOn;
        this.isAuthenticated = isAuthenticated;
        this.claims = claims;
        this.avatarImage = avatarImage;
        this.sessionCreated = sessionCreated;
    }
}
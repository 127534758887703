import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalsService } from '../_core/globals.service';
import { PortalAuthenticationService } from '../_services/dx-portal-auth.service';

@Injectable()
export class PortalAuthGuard implements CanActivate {
  constructor(private authSvc: PortalAuthenticationService, private globals: GlobalsService,
    private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let currentUser: any = this.authSvc.getCachedUser();
    // Get property name on security object to check
    let claimType: string = next.data["claimType"];
    return this.authSvc.checkClaimsAcess(Number(claimType));
   
    //
  
    //if(claimType === 'landingPage'){
      //return true;
    //}
    //if (this.authSvc.loggedOnUser.isAuthenticated
      //&& this.authSvc.hasClaim(claimType, "canRead")) {
      //return true;
   // }
    //else {
      //let redirectUrl = this.globals.env.UXDomainUrl + '/unauthorized';
      //if(state.url){
        // redirectUrl  = redirectUrl + "?returnUrl=" + state.url
     // }
      //window.location.replace(redirectUrl);
      //this.router.navigate(['unauthorized'],
       // { queryParams: { returnUrl:  } });
      //return false;
   // }
  }
}

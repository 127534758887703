import { Component } from '@angular/core';
import { GlobalsService } from '../_core/globals.service';
import { ActivatedRoute } from '@angular/router';
//import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {

  message: string;
  returnUrl: string = '';
  constructor(
    private globals: GlobalsService,
    private route: ActivatedRoute) { 
      this.message =  String(this.route.snapshot.paramMap.get('message'));
      this.returnUrl = this.route.snapshot.queryParams["returnUrl"];
      if(!this.message)
        this.message = ''
    }

  navigateToPartnerLogin(): void {
      let redirectUrl = ''//this.globals.env.UXDomainUrl + '/login';
      if(this.returnUrl){
         redirectUrl = redirectUrl + "?returnUrl=" + this.returnUrl;
      }
      window.location.replace(redirectUrl);
  }

  navigateToAuthSso(): void {
    
  }

}
